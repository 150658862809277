import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames';
import AnchorButton from './shared/AnchorButton'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import { useSiteMetadata } from './SiteData'

const useStyles = makeStyles(theme => ({
	container: {
		alignContent: 'flex-start',
		margin: [[0, 'auto']],
	},
	centered: {
		justifyContent: 'space-around',
	},
	jumbo: {
		[theme.breakpoints.up('md')]: {
			padding: "16px 30px",
		},
	},
	note: {
		marginTop: theme.spacing(1),
		fontSize: "16px",
	},
}), {name: "SignUpAndDemoButtons"});

export default function SignUpAndDemoButtons({ centered, jumbo, includePlanNote, outline, webLinkText, color = "primary", className }) {
	let variant = outline ? "outlined" : "contained";
	const {webAppUrl} = useSiteMetadata();
	const classes = useStyles();
	return null;
	// return (
	// 	<Grid container className={cn(classes.container, {[classes.centered]: centered})} style={{lineHeight: "3rem", textAlign: "center"}}>
	// 		<Grid item xs="auto">
	// 			<AnchorButton variant={variant} color={color} size="large" href={webAppUrl + "/app/signup"} className={cn({[classes.jumbo]: !!jumbo}, className)}>
	// 				{webLinkText || 'Get Started Now!'}
	// 			</AnchorButton>
	// 			{includePlanNote && (
	// 				<Typography className={classes.note}>
	// 					Free Plan Available
	// 				</Typography>
	// 			)}
	// 		</Grid>
	// 	</Grid>
	// );
}
SignUpAndDemoButtons.propTypes = {
	centered: PropTypes.bool,
	jumbo: PropTypes.bool,
	/** include a stock, trial note...*/
	includePlanNote: PropTypes.bool,
	outline: PropTypes.bool,
	webLinkText: PropTypes.any,
	color: PropTypes.oneOf(['initial', 'inherit', 'primary', 'secondary', 'textPrimary', 'textSecondary', 'error',]),
	className: PropTypes.string,
}
