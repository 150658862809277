import React from 'react'
import * as PropTypes from 'prop-types'
import cn from 'classnames';
import { useSiteMetadata } from '../components/SiteData'
import AppLayout from "../components/AppLayout";
import SignUpAndDemoButtons from '../components/SignUpAndDemoButtons'
import PageInfo from '../components/PageInfo'
import RouterLink from '../components/shared/RouterLink'
import { graphql } from 'gatsby'
import Brand from '../components/shared/Brand'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Spacing from '../reused-libraries/Spacing'
import { GatsbyImage } from "gatsby-plugin-image";
import HeaderNavigation from '../components/page/HeaderNavigation'
import { ForceHeadlineDarkTheme } from '../reused-libraries/materialTheme'
import { findColorByIndex, radialGradientStyle } from '../components/themeColors'
import { SchemaMarkupRoot } from '../components/common/SchemaMarkup'
import { useHeadlinePost } from '../components/ArticleData'
import useTheme from '@material-ui/core/styles/useTheme'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { BlogPostCard } from './blog'
import RouterButton from '../components/shared/RouterButton'

export default function Home(props) {
	let {data} = props;
	let {productName} = useSiteMetadata();

	let headlinePost = useHeadlinePost();

	const logoVariant = "whiteout";

	return (
		<AppLayout
			logoVariant={logoVariant}
			renderAboveTheFold={() => (
				<ForceHeadlineDarkTheme>
					<SwooshBg>
						<HeaderNavigation logoVariant={logoVariant}/>
						<Headline data={data}/>
					</SwooshBg>
				</ForceHeadlineDarkTheme>
			)}
		>
			<PageInfo
				title={"Free Time Tracking App - Track Work Hours, Expenses & Create Invoices"}
				description={"Keep track of work hours, expenses, and send automatic invoices with our time tracking app designed for individuals and teams. Try " + productName + " today for free."}
			/>
			<SchemaMarkupRoot/>
		</AppLayout>
	);
}

const useSwooshBgStyles = makeStyles(theme => ({
	swooshContainer: {
		position: "relative",
		display: "flex",
		flexDirection: "column",
		maxWidth: "100%",
		flex: "1 1 100%",
	},
	swooshMiddleContainer: {
		position: "absolute",
		display: "flex",
		justifyContent: "space-around",
		width: "100%",
		height: "100%",
	},
	swoosh: {
		[theme.breakpoints.up('md')]: {
			minHeight: "100%",
		},
		position: "absolute",
		width: "1440px",
		border: "0px",
		transform: "skew(45deg,0deg)",
		backgroundColor: findColorByIndex(2),  // fallback for old browsers
		background: "linear-gradient(170deg, " + findColorByIndex(1) + ", " + findColorByIndex(2) + ")", // W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+
	},
	childContent: {
		color: theme.palette.text.primary,
		position: "relative",
		flexGrow: "1",
		display: "flex",
		flexDirection: "column",
	},
}), {name: "SwooshBg"});

function SwooshBg(props) {
	let {children} = props;
	const classes = useSwooshBgStyles();

	return (
		<div className={cn(classes.swooshContainer)}>
			<div className={cn(classes.swooshMiddleContainer)}>
				<div className={classes.swoosh}/>
			</div>

			<div className={classes.childContent}>
				{children}
			</div>
		</div>
	);
}
SwooshBg.propTypes = {
	children: PropTypes.any,
};

const useHeadlineStyles = makeStyles(theme => ({
	mainHeadline: {
		display: "flex",
		flexDirection: "column",
		justifyContent: "space-between",
		position: "relative",

		[theme.breakpoints.up('md')]: {
			minHeight: "100%",
			flexGrow: "1",
			marginTop: theme.spacing(2),
		},
	},
	mainImage: {
		position: 'relative',
		marginTop: theme.spacing(4),
		[theme.breakpoints.up('lg')]: {
			marginTop: 0,
		},
	},
	mainHeadlineWrapper: {
		display: "flex",
		flexDirection: "column",
		justifyContent: "start",
		flexGrow: "1",
	},
	mainHeadlineContent: {
		flexGrow: "1",
		display: "flex",
		flexDirection: "column",
		marginTop: theme.spacing(6),
		// [theme.breakpoints.up('md')]: {
		// 	marginTop: "68px",
		// },
		// [theme.breakpoints.up('lg')]: {
		// 	marginTop: "96px",
		// },
		[theme.breakpoints.up('sm')]: {
			padding: [[0, theme.spacing(6)]],
		},
	},
	mainTitle: {
		// fontSize: theme.typography.pxToRem(23),
		// [theme.breakpoints.up('md')]: {
		// 	fontSize: theme.typography.pxToRem(25),
		// },
	},
	subTitle: {
		marginTop: theme.spacing(4),
		fontSize: theme.typography.pxToRem(12),
		[theme.breakpoints.up('md')]: {
			// marginTop: "42px",
			fontSize: theme.typography.pxToRem(14),
		},
	},
	floatingContentArea: {
		flexGrow: "1",
		margin: [[theme.spacing(6), 0]],
	},
	image: {
		boxShadow: theme.shadows[16],
	},
}), {name: "Headline"});

function Headline(props) {
	let {data} = props;
	let {productName} = useSiteMetadata();
	const classes = useHeadlineStyles();

	const theme = useTheme();
	const isLgPlus = useMediaQuery(theme.breakpoints.up('lg'));

	const imgMaxWidth = 600;

	return (
		<section className={classes.mainHeadline}>
			<div className={classes.mainHeadlineWrapper}>
				<div className={cn(classes.mainHeadlineContent, "container-fluid")}>
					<div style={{flexGrow: 2}}/>
					<Grid container spacing={2}>
						<Grid item xs={12} lg={5}>
							<Typography variant="h1" className={classes.mainTitle}>
								The Time Tracker with Automatic Invoicing
							</Typography>

							<Typography component="h2" className={classes.subTitle}>
								{/*Access your time and expenses from your mobile device or the web.<br/>*/}
								{/*Remove the overhead from tracking your work.<br/>*/}
								Store your time and expenses online, and automate invoices for clients. You'll be getting paid before you know it.
							</Typography>

{/*TODO: Maybe get rid of 'Get Started' in the top nav like Stripe?*/}
{/*							{isLgPlus && (*/}
{/*								<Spacing mt={6}>*/}
{/*									<SignUpAndDemoButtons webLinkText="Start tracking time" jumbo includePlanNote/>*/}
{/*								</Spacing>*/}
{/*							)}*/}
						</Grid>
						<Grid item xs={12} lg={7}>
{/*TODO: Visual showing off LogSpace in all its glory. Get as close to reality as possible. No fancy illustrations. Can we show LogSpace "in action"?*/}

{/* TODO: there is a bit of wasted space here that I am not sure how to reclaim.  It is because of the comment 2 lines down. */}
							<div className={classes.mainImage}>
								{/*This image isn't absolutely positioned so it takes up its non-transformed space*/}
								<TransformedContent
									maxWidth={imgMaxWidth}
									transform="translate(-2%, -7%) perspective(1000px) rotate3d(0, 1, -0.2, -20deg) scale(0.9)"
								>
									<GatsbyImage
										alt="Active Time Log"
										image={data.clockedIn.childImageSharp.gatsbyImageData}
										className={classes.image}
									/>
								</TransformedContent>
								<AbsPositionImage>
									<TransformedContent
										maxWidth={imgMaxWidth}
										transform="translate(-5%, -50%) perspective(1000px) rotate3d(0, 1, -0.2, -20deg) scale(0.5) translate(-50%,10%)"
									>
										<GatsbyImage
											alt="Sample Invoice"
											image={data.invoice.childImageSharp.gatsbyImageData}
											className={classes.image}
										/>
									</TransformedContent>
								</AbsPositionImage>
								{/*
								<AbsPositionImage>
									<TransformedContent
										maxWidth={imgMaxWidth}
										transform="perspective(1000px) translate(60%, -7%) rotate3d(0, 1, -0.2, 20deg) rotate(-8deg) scale(0.9)"
									>
										<ImgWithFloatingClippedImg
											primaryImageData={data.clockedIn.childImageSharp.gatsbyImageData}
											primaryImageAlt="Active Time Log"
											primaryImageMaxWidth={600}
											className={imgClassName}
											floatingImageData={data.sampleInvoice.childImageSharp.gatsbyImageData}
											floatingImageAlt="Sample Invoice"
										/>
									</TransformedContent>
								</AbsPositionImage>
								*/}
							</div>
						</Grid>
					</Grid>
					<div style={{flexGrow: 3}}/>
					{/*<div className={classes.floatingContentArea}>*/}
					{/*	<ForceLightTheme>*/}
					{/*		<TrustSignals/>*/}
					{/*	</ForceLightTheme>*/}
					{/*</div>*/}
					{/*<div style={{flexGrow: 1}}/>*/}
				</div>
				{/*{!isLgPlus && (*/}
				{/*	<Spacing mt={4} mb={4}>*/}
				{/*		<SignUpAndDemoButtons centered jumbo includePlanNote/>*/}
				{/*	</Spacing>*/}
				{/*)}*/}
				{/*<div style={{height: '5vw', transform: 'translateY(1px)'}}>*/}
				{/*	<svg viewBox="0 0 100 100" preserveAspectRatio="none" style={{width: '100%', height: '100%'}}>*/}
				{/*		<path d="M0,0 L0,100 L100,100 L100,85 Z" fill="#fbfbfb"/>*/}
				{/*	</svg>*/}
				{/*</div>*/}
			</div>
		</section>
	);
}
Headline.propTypes = {

};

const useAbsPositionImageStyles = makeStyles(theme => ({
	absPosition: {
		position: 'absolute',
		top: '50%',
		left: '50%',
		width: '100%',
		height: '1px',
	},
}), {name: "AbsPositionImage"});

function AbsPositionImage(props) {
	const {children} = props;
	const classes = useAbsPositionImageStyles();
	return (
		<div className={classes.absPosition}>
			{children}
		</div>
	);
}
AbsPositionImage.propTypes = {
	children: PropTypes.any.isRequired,
};

const useTransformedContentStyles = makeStyles(theme => ({
	container: {
		margin: [[0, "auto"]],
		maxWidth: props => props.maxWidth,
	},
}), {name: "TransformedContent"});

function TransformedContent(props) {
	const {maxWidth, transform, children} = props;
	const classes = useTransformedContentStyles({
		maxWidth: maxWidth,
	});
	return (
		<div className={classes.container} style={{transform: transform}}>
			{children}
		</div>
	);
}
TransformedContent.propTypes = {
	/** The content's max width */
	maxWidth: PropTypes.number.isRequired,
	/** The CSS transform attribute */
	transform: PropTypes.string.isRequired,
	/** The content to transform */
	children: PropTypes.any.isRequired,
};

const useFloatingImagesStyles = makeStyles(theme => ({
	imgRelative: {
		position: 'relative',
		width: '1px',
		marginLeft: 'auto',
		marginRight: 'auto',
	},
	timesheetPicWrapper: {
		position: "absolute",
		top: 123,
		right: 14,
		[theme.breakpoints.down('sm')]: {
			right: 7,
		},
		[theme.breakpoints.down('xs')]: {
			right: -42,
		},
	},
	timesheetPic: {
		width: "630px",
		borderRadius: "8px",
		boxShadow: "0px 0px 10px 0px rgba(0, 0, 0, 0.40)",
		[theme.breakpoints.down('md')]: {
			width: "472px",
		},
		[theme.breakpoints.down('sm')]: {
			width: "378px",
		},
		[theme.breakpoints.down('xs')]: {
			width: "315px",
		},
	},
	invoicePicWrapper: {
		position: "absolute",
		top: 0,
		left: 35,
		[theme.breakpoints.down('sm')]: {
			top: 40,
			left: 18,
		},
		[theme.breakpoints.down('xs')]: {
			left: -9,
		},
	},
	invoicePic: {
		width: "612px",
		boxShadow: "0px 0px 10px 0px rgba(0, 0, 0, 0.40)",
		[theme.breakpoints.down('md')]: {
			width: "460px",
		},
		[theme.breakpoints.down('sm')]: {
			width: "367px",
		},
		[theme.breakpoints.down('xs')]: {
			width: "306px",
		},
	},
}), {name: "FloatingImages"});

function FloatingImages(props) {
	const {timesheetImgData, invoiceImgData} = props;
	const classes = useFloatingImagesStyles();
	return (
		<div className={classes.imgRelative}>
			<div className={classes.timesheetPicWrapper}>
				<GatsbyImage
					image={timesheetImgData}
					alt="A stylized timesheet."
					className={classes.timesheetPic}
					// fromStyle={{transform: "translate(-25px, 200px) rotate3d(0,0,1,-2deg)"}}
					style={{transformOrigin: "left top", transform: "rotate(-8deg)"}}
					loading="eager"
				/>
			</div>
			<div className={classes.invoicePicWrapper}>
				<GatsbyImage
					image={invoiceImgData}
					alt="A stylized invoice."
					className={classes.invoicePic}
					// fromStyle={{transform: "translate(25px, 200px) rotate3d(0,0,1,6deg)"}}
					style={{transformOrigin: "left top", transform: "rotate(12deg)"}}
					loading="eager"
				/>
			</div>
		</div>
	);
}
FloatingImages.propTypes = {
	timesheetImgData: PropTypes.object.isRequired,
	invoiceImgData: PropTypes.object.isRequired,
};

const useMainHeadlineBannerStyles = makeStyles(theme => ({
	banner: {
		textAlign: "center",
		backgroundColor: "rgba(40, 40, 40, 0.88)",
		color: "#eee",
		fontSize: theme.typography.pxToRem(16),
		padding: "0.35rem 1rem",
		"& a:hover": {
			textDecoration: "none",
		},
		"& a,a:active,a:visited": {
			textDecoration: "underline",
		},
	},
}), {name: "MainHeadlineBanner"});

function MainHeadlineBanner(props) {
	const {headlinePost} = props;
	const classes = useMainHeadlineBannerStyles();
	return (
		<>
			{!headlinePost ? (
				<div className={classes.banner}>
					Watch this space to see the latest updates from <Brand/>.
				</div>
			) : (
				<>
					<Spacing px={2} style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
						<Spacing pb={3} style={{maxWidth: '400px'}}>
							{/*{' '}<RouterLink to={headlinePost.path} color="inherit">{headlinePost.title}</RouterLink>*/}
							<BlogPostCard
								elevation={8}
								header="Latest update from our blog:"
								post={headlinePost}
								suppressPreviewText
							/>
						</Spacing>
						<div>
							<RouterButton size="small" variant="outlined" color="primary" to="/blog/">
								See All Blog Posts
							</RouterButton>
						</div>
					</Spacing>
				</>
			)}
		</>
	);
}
MainHeadlineBanner.propTypes = {
	headlinePost: PropTypes.shape({
		path: PropTypes.string.isRequired,
		title: PropTypes.string.isRequired,
		previewText: PropTypes.string.isRequired,
		previewImage: PropTypes.object,
	}),
};

const useMainTaglineStyles = makeStyles(theme => ({
	mainTagline: {
		textAlign: "center",
		margin: [[0, theme.spacing(2)]],
		height: '100%',
	},
	contentWrapper: {
		height: '100%',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		padding: theme.spacing(4),
		maxWidth: "1200px",
		color: theme.palette.grey[200],
		...radialGradientStyle(theme, findColorByIndex(0), findColorByIndex(1)),
		// ...radialGradientStyle(theme.palette.primary.dark, lighten(theme.palette.primary.dark, 0.3)),
		// boxShadow: "0 48px 48px -50px rgba(0,0,0,0.2), 0 64px 64px -30px rgba(0,0,0,0.4)",
		boxShadow: theme.shadows[8],
		borderRadius: "24px",
	},
	buttonLinkBoring: {
		fontWeight: "bold",
		whiteSpace: "nowrap",
	},
	icon: {
		borderRadius: "50%",
		boxShadow: theme.shadows[5],
	},
	featureIcon: {
		color: findColorByIndex(1),
	},
}), {name: "MainTagline"});

function MainTagline(props) {
	const classes = useMainTaglineStyles();

	const featureIcons = [
		{faIcon: ["far", "clock"], title: "Time Tracking", link: "/features/"},
		{faIcon: ["fas", "coins"], title: "Expenses", link: "/features/expenses/"},
		{faIcon: ["fas", "file-invoice"], title: "Invoicing", link: "/features/invoicing/"},
		{faIcon: ["fas", "table"], title: "Reports", link: "/features/reporting/"},
	];

	return (
		<section className={classes.mainTagline}>
			<div className={classes.contentWrapper}>
				<Spacing mx={2} mb={8}>
					<Typography variant="body1">
						{/*
						Keep a record of expenses and time spent working on projects.
						Create invoices for your clients.<br/>
						*/}
						<Brand/> is a time and expense tracking system enhanced with automatic invoicing.<br/>
						And it's built for your growing team.
					</Typography>
				</Spacing>
				<Grid container className="justify-content-center">
					{featureIcons.map((icon, idx) => (
						<Grid key={idx} item xs={6} sm={3}>
							<RouterLink color="inherit" to={icon.link} suppressDecoration className={classes.buttonLinkBoring}>
								<div>
									<span className={cn(classes.icon, "fa-layers fa-4x")}>
										<FontAwesomeIcon icon="circle"/>
										<FontAwesomeIcon icon={icon.faIcon} transform="shrink-8" title={icon.title} className={classes.featureIcon}/>
									</span>
								</div>
								<div className="mt-3 mb-4">{icon.title}</div>
							</RouterLink>
						</Grid>
					))}
				</Grid>
			</div>
		</section>
	);
}

const useAccentedSectionStyles = makeStyles(theme => ({
	accentSection: {
		background: 'linear-gradient(15deg, ' + findColorByIndex(0) + ', ' + findColorByIndex(1) + ')',
		color: "#fff",
		paddingTop: "3rem",
		paddingBottom: "3rem",
		fontSize: theme.typography.pxToRem(20),
		[theme.breakpoints.down('sm')]: {
			fontSize: theme.typography.pxToRem(16),
		},
	},
}), {name: "AccentedSection"});

export function AccentSection(props) {
	let {className, children} = props;
	const classes = useAccentedSectionStyles();
	return (
		<section className={cn(className, classes.accentSection)}>
			<div className="container-fluid">
				<Spacing px={2.5}>
					{children}
				</Spacing>
			</div>
		</section>
	);
}
AccentSection.propTypes = {
	className: PropTypes.string,
	children: PropTypes.any,
};

/*
function MobileAppButtons(props) {
	return (
		<div className="text-center">
			<a href="https://itunes.apple.com/us/app/logspace/id1457386667?ls=1&mt=8">
				<img alt="Download on the App Store" className="m-4" height="60" src={appstoreImg}/>
			</a>
			<a href="https://play.google.com/store/apps/details?id=com.momentsoftware.logspace">
				<img alt="Get it on Google Play" className="m-4" height="60" src={googlePlayImg}/>
			</a>
		</div>
	);
}
*/

function TryItForYourself(props) {
	return (
		<div className="text-center">
			<p className="px-4">But don't take our word for it&mdash;try it for yourself.</p>
			<div>
				<SignUpAndDemoButtons centered includePlanNote/>
			</div>
		</div>
	);
}
TryItForYourself.propTypes = {

};

export const query = graphql`{
  invoice: file(relativePath: {eq: "images/Invoice.png"}) {
    childImageSharp { gatsbyImageData(width: 612, placeholder: BLURRED, layout: CONSTRAINED) }
  }
  timesheet: file(relativePath: {eq: "images/Timesheet.png"}) {
    childImageSharp { gatsbyImageData(width: 630, placeholder: BLURRED, layout: CONSTRAINED) }
  }
  clockedIn: file(relativePath: {eq: "images/clocked-in.png"}) {
    childImageSharp { gatsbyImageData(width: 750, placeholder: BLURRED, layout: CONSTRAINED) }
  }
  timesheetView: file(relativePath: {eq: "images/timesheet-view.png"}) {
    childImageSharp { gatsbyImageData(width: 750, placeholder: BLURRED, layout: CONSTRAINED) }
  }
  teamMemberActivity: file(relativePath: {eq: "images/team-member-activity.png"}) {
    childImageSharp { gatsbyImageData(width: 750, placeholder: BLURRED, layout: CONSTRAINED) }
  }
  sampleInvoice: file(relativePath: {eq: "images/sample-invoice.png"}) {
    childImageSharp { gatsbyImageData(width: 500, placeholder: BLURRED, layout: CONSTRAINED) }
  }
  logspaceIphoneWebScreenshotImg: file(relativePath: {eq: "images/logspace-iphone-web-screenshot.png"}) {
    childImageSharp { gatsbyImageData(width: 600, placeholder: BLURRED, layout: CONSTRAINED) }
  }
}
`;
